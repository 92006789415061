import {Link as LinkBase, useParams} from '@remix-run/react';
import {LogIn, Newspaper} from 'lucide-react';
import {Header, HeaderTitle} from '~/ui/Header';
import {ButtonLink} from '~/ui/button-link';
import {Separator} from '~/ui/separator';
import {useDictionary} from '../$lang/route';

export const PublicHeader = () => {
  const {t} = useDictionary();
  const {lang} = useParams();
  return (
    <Header className="h-24 gap-4">
      <HeaderTitle subtitle="quest" src="/logo.png" as={LinkBase} to={lang ? `/${lang}` : '/'} />
      <div className="flex-1" />
      <ButtonLink icon={Newspaper} variant="ghost" className="hidden sm:flex" to={(lang ? `/${lang}` : '') + '/blog'}>
        {t(l => l.blog)}
      </ButtonLink>
      <Separator orientation="vertical" className="hidden sm:inline h-8" />
      <ButtonLink icon={LogIn} colored className="hidden sm:flex" to={(lang ? `/${lang}` : '') + '/login'}>
        {t(l => l.login)}
      </ButtonLink>
    </Header>
  );
};
