import {LoaderFunctionArgs, redirect} from '@remix-run/node';
import {Outlet} from '@remix-run/react';
import {safeRemixTrpcCall} from '~/core/api.server';
import {View} from '~/ui/view';
import {PublicHeader} from './PublicHeader';

export async function loader({request, params}: LoaderFunctionArgs) {
  const locale = params.lang;
  const getCurrentUser = await safeRemixTrpcCall(request, caller => caller.auth.getCurrentUser());
  if (getCurrentUser.success && getCurrentUser.data?.id) {
    throw redirect(`/${locale}/app/quests`);
  }
  return {};
}

export default function App() {
  return (
    <View>
      <div>
        <PublicHeader />
        <div className="flex-1">
          <Outlet />
        </div>
      </div>
    </View>
  );
}
